import React, { lazy } from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './configs'

import DetectDeviceProvider from 'wsc/components/context/DetectDeviceProvider'
import { PreviewSiteBannerProvider } from 'wsc/components/context/PreviewSiteBannerProvider'
import { NavbarProvider } from 'wsc/components/context/NavbarProvider'
import { LocaleProvider } from './components/context/LocaleProvider'
import { PostNavProvider } from 'wsc/components/context/PostNavProvider'
import OnetrustProvider from 'wsc/components/context/OnetrustProvider'

import routes from './configs/routes'
import client from './utils/initApollo'
import GlobalStyle from './components/styled/GlobalStyle'
import ScrollToTop from './components/route/ScrollToTop'
import HistoryChangeHandler from './components/HistoryChangeHandler'
import ReactComponentLoader from 'wsc/components/ReactComponentLoader'

const Home = lazy(() => import('./pages/Home'))
const Post = lazy(() => import('./pages/Post'))
const Tag = lazy(() => import('./pages/Tag'))
const AuthorIndex = lazy(() => import('./pages/AuthorIndex'))
const Category = lazy(() => import('./pages/Category'))
const NotFound = lazy(() => import('./pages/NotFound'))
const Error = lazy(() => import('./pages/Error'))
const Author = lazy(() => import('./pages/Author'))
const Series = lazy(() => import('./pages/Series'))
const About = lazy(() => import('./pages/About'))
const Newsletter = lazy(() => import('./pages/Newsletter'))
const ContentIndex = lazy(() => import('./pages/ContentIndex'))
const Contact = lazy(() => import('./pages/Contact'))
const Webinar = lazy(() => import('./pages/Webinar'))
const Submissions = lazy(() => import('./pages/Submissions'))

const App = () => (
  <LocaleProvider>
    <OnetrustProvider>
      <ApolloProvider client={client}>
        <DetectDeviceProvider>
          <PreviewSiteBannerProvider>
            <PostNavProvider>
              <NavbarProvider>
                <GlobalStyle />
                <BrowserRouter>
                  <HistoryChangeHandler />
                  <ScrollToTop />
                  <ReactComponentLoader viewportLazyLoad={false}>
                    <Switch>
                      <Route exact path={routes.contact.path} component={Contact} />
                      <Route exact path={routes.homepage.path} component={Home} />
                      <Route exact path={routes.about.path} component={About} />
                      <Route exact path={routes.newsletter.path} component={Newsletter} />
                      <Route exact path={routes.error.path} component={Error} />
                      <Route exact path={routes.author.path} component={Author} />
                      <Route exact path={routes.authorIndex.path} component={AuthorIndex} />
                      <Route exact path={routes.tag.path} component={Tag} />
                      <Route exact path={routes.contentIndex.path} component={ContentIndex} />
                      <Route exact path={routes.series.path} component={Series} />
                      <Route exact path={routes.webinar.path} component={Webinar} />
                      <Route exact path={routes.submissions.path} component={Submissions} />
                      <Route exact path={routes.post.path} component={Post} />
                      <Route exact path={routes.category.path} component={Category} />
                      <Route component={NotFound} />
                    </Switch>
                  </ReactComponentLoader>
                </BrowserRouter>
              </NavbarProvider>
            </PostNavProvider>
          </PreviewSiteBannerProvider>
        </DetectDeviceProvider>
      </ApolloProvider>
    </OnetrustProvider>
  </LocaleProvider>
)

export default App
